// src/utils/languageUtils.js

import { LNG_DEFAULT } from "@/utils/constants";
import route_nlBE from "@/assets/static/nl-BE/route.json";
import route_frBE from "@/assets/static/fr-BE/route.json";

// Define the languages constants directly in this file
const LanguesFR = ["fr-BE", "fr-FR", "fr", "fr-CA", "fr-CH", "fr-LU", "fr-MC"];
const LanguesNL = ["nl-BE", "nl-NL", "nl", "nl-AW", "nl-BQ", "nl-CW", "nl-SX"];
const LanguesEN = ["en-US", "en-GB", "en", "en-AU", "en-CA", "en-NZ", "en-IE"];

// Use a variable to track if determineLang has been called
let determineLangCalled = false;
let determinedLang = null;

export const determineLang = () => {
  // Check if it has already been called
  if (determineLangCalled) {
    return determinedLang;
  }

  const urlArray = window.location.pathname.split("/");

  const urlLang = urlArray[1];
  const localStorageLang = localStorage.getItem("USERLANG");
  //console.log("🚀 ~ start determineLang", localStorageLang, urlLang);
  // Determine the language
  let lang;
  if (urlLang === "belgie") {
    lang = "nl-BE";
  } else if (urlLang === "belgique") {
    lang = "fr-BE";
  } else {
    lang =
      localStorageLang ||
      navigator.language?.split("-")[0] ||
      navigator.userLanguage?.split("-")[0] ||
      LNG_DEFAULT;
  }

  // Set the flag to true and store the determined language
  determineLangCalled = true;
  determinedLang = lang;
  //console.log("🚀 ~ end determineLang ~ determinedLang:", determinedLang);
  return determinedLang;
};

export const resetDetermineLang = () => {
  determineLangCalled = false;
  determinedLang = null;
};

export const changeAppLanguage = async (
  langStore,
  setLang,
  setProperties,
  setRouteMap
) => {
  resetDetermineLang();

  let folderLang = "nl-BE";
  if (LanguesFR.includes(langStore)) {
    folderLang = "fr-BE";
  } else if (LanguesNL.includes(langStore)) {
    folderLang = "nl-BE";
  } else if (LanguesEN.includes(langStore)) {
    folderLang = "nl-BE";
  }

  const textPath = `/static/${folderLang}/text.json`;
  try {
    const textResponse = await fetch(textPath);
    if (!textResponse.ok) {
      throw new Error(`Failed to fetch text: ${textResponse.status}`);
    }
    const textJson = await textResponse.json();
    setLang(textJson);
  } catch (error) {
    console.error("Error fetching text:", error);
  }

  const propsPath = `/static/${folderLang}/properties.json`;
  try {
    const propsResponse = await fetch(propsPath);
    if (!propsResponse.ok) {
      throw new Error(`Failed to fetch properties: ${propsResponse.status}`);
    }
    const propsJson = await propsResponse.json();
    setProperties(propsJson);
  } catch (error) {
    console.error("Error fetching properties:", error);
  }

  if (folderLang == "fr-BE") {
    setRouteMap(route_frBE);
  } else {
    setRouteMap(route_nlBE);
  }

  // const routeMapPath = `/static/${folderLang}/route.json`;
  // try {
  //   const routeResponse = await fetch(routeMapPath);
  //   if (!routeResponse.ok) {
  //     throw new Error(`Failed to fetch routes: ${routeResponse.status}`);
  //   }
  //   const routeJson = await routeResponse.json();
  //   setRouteMap(routeJson);
  // } catch (error) {
  //   console.error("Error fetching routes:", error);
  // }

  localStorage.setItem("USERLANG", langStore);
  localStorage.setItem("USERFOLDERLANG", folderLang);
};

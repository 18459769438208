import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname, state } = useLocation();

  useEffect(() => {
    //console.log(pathname, state);
    if (state?.scrollToTop !== false) {
      window.scrollTo(0, 0, "smooth");
    }
  }, [pathname, state]);
};

export default ScrollToTop;
